// Typographie pour header

@mixin link-style {
  width: 8.5rem;
  color: #000;
  text-align: right;
  font-family: Montserrat;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 142.6%;
  text-decoration: none;
}

// Typographie pour HomePage

@mixin concept-title {
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Montserrat;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 142.6%;
}

@mixin title-card {
  color: #fff;
  font-family: Montserrat;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 142.6%;
}

// • Typographie composant footer
@mixin footer-style {
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 142.6%;
}

// Typographie Page annonceLogement

@mixin title-style {
  color: #ff6060;
  font-family: Montserrat;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 500;
}

@mixin text-style {
  color: #000;
  font-family: Montserrat;
  font-size: 1.125rem;
  font-style: normal;
  text-align: justify;
  text-wrap: pretty;
  font-weight: 300;
}

@mixin contenaire-style {
  color: white;
  border-radius: 0.3125rem;
  background: #ff6060;
  text-align: center;
  font-family: Montserrat;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
}
