@import '../../3.Layouts/_mixins';
@import '../../2.Utils/Keyframes';

.annonce-contenaire {
  width: auto;
  margin-bottom: 2.36rem;
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.contenaire_titre {
  height: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 98%;
  @media screen and (max-width: 650px) {
    height: 1rem;
  }

  .contenaire_identite {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 10rem;
    align-items: center;
    margin-top: 1rem;
    gap: 1rem;
    @media screen and (max-width: 650px) {
      position: relative;
      top: 11rem;
      font-size: 0.75rem;
      gap: 0.5rem;
    }
  }
  .annonce-title {
    @include title-style;
    width: auto;
    @media screen and (max-width: 650px) {
      font-size: 1.125rem;
    }
  }

  .annonce-name {
    @include text-style;
    color: #ff6060;
    inline-size: 2px;
    writing-mode: horizontal-tb;
    width: auto;
  }

  .annonce-photo {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
  }
}

.annonce-location {
  height: 1.8125rem;
  margin: 0px;
  padding-top: 1.25rem;
  @include text-style;
  @media screen and (max-width: 650px) {
    font-size: 0.75rem;
    font-weight: 500;
  }
}

.contenaire_comments {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: baseline;
  flex-wrap: nowrap;
  @media screen and (max-width: 650px) {
    flex-direction: column;
    margin-bottom: 2.5rem;
    height: 10rem;
  }

  .contenaire_tags {
    display: flex;
    gap: 2rem;
    margin-right: 2rem;
  }

  .annonce-tags {
    height: 1.5625rem;
    @include contenaire-style;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    cursor: pointer;
  }
}

.box-description-equipments {
  display: grid;
  gap: 4.75rem;
  width: 100%;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr;
  @media screen and (max-width: 650px) {
    gap: 1.25rem;
    grid-template-columns: 1fr;
  }

  .tools {
    display: flex;
    width: 2rem;
  }

  .contenaire_description {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding: 0.625rem 1.25rem 0.625rem 0.625rem;
    @include contenaire-style;
  }

  .arrow-back {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
  }

  .arrow {
    width: 2rem;
    height: 2rem;
    rotate: -180deg;
    cursor: pointer;
  }

  .annonce-description {
    font-weight: 300;
    display: block;
    animation: container-view 2s ease-in-out;
    @include text-style;
  }

  .annonce-clear {
    display: none;
  }

  .contenaire_equipments {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    @include contenaire-style;
    padding: 0.625rem 1.25rem 0.625rem 0.625rem;
  }

  .annonce-equipments {
    animation: container-view 7s ease-in-out;
    display: block;
  }

  .equipement {
    @include text-style;
    margin: 12px;
  }
}

.stars-wrapper {
  display: flex;
  width: 12.25rem;
  height: 2.25rem;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 15px;
  @media screen and (max-width: 650px) {
    position: relative;
    width: auto;
    bottom: 1rem;
  }

  .stars-notation {
    size: 15px;
    width: 25px;
  }

  .starsW {
    color: #ff6060;
  }

  .starsE {
    filter: grayscale(1);
  }
}
