// https://www.figma.com/file/
// Charte graphique Maquette

// Variables couleurs:
$secondaireColor:#FF6060;
$whiteColor:#fff;
$colorText:#000;



// Police :
$textFont: Montserrat, sans-serif;



// Background-color:
$backgroundColorMain: #f6f6f6;
$colorText:#000;
$backgroundColorCard:linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(10, 10, 10, 0.00) 42%, rgba(4, 4, 4, 0.20) 99.99%, gba(0, 0, 0, 0.50) 100%); 
