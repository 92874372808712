@import '../../3.Layouts/globalStyle';
@import '../../3.Layouts/mixins';
@import '../../2.Utils/Keyframes';

// format descktop

.contenair-apropos {
  margin-bottom: 2.36rem;
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.banner-apropos {
  height: 13.9375rem;
  border-radius: 1.5625rem;
  background: #000;
}

.img-apropos {
  opacity: 0.5;
  border-radius: 1.5625rem;
  height: 100%;
  width: 100%;
}

.rank {
  display: flex;
  align-items: center;
  background-color: $secondaireColor;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  color: white;
  border-radius: 0.3125rem;
  margin-top: 2.375rem;
  padding-left: 0.94rem;
  @include title-card;
}
.togglebtn {
  background-color: #ff6060;
  border: none;
  border-radius: 5px;
}

.icones-apropos {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  padding-right: 1.5rem;
}

.icones-apropos:active {
  transform: rotate(180deg);
  margin-left: 2rem;
}

.arrow-back {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

.arrow {
  width: 2rem;
  height: 2rem;
  rotate: -180deg;
  cursor: pointer;
}

.annonce-descriptionP {
  display: block;
  animation: container-view 2s ease-in-out;
  @include text-style;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  padding-top: 1.5rem;

  p {
    padding-top: 0rem;
    margin-top: 0;
  }
}

.annonce-clear {
  display: none;
}

.button {
  display: contents;
}
