

@keyframes container-view {
    0% {
        overflow: hidden;
        max-height: 0%;
        opacity: 0;
    }

    5% {
        opacity: 1;
        max-height: 10%;
    }
    
    50% {
        max-height: 50%;
    }
    
    100% {
        max-height: 100%;
    }
  }
