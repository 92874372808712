@import '../../3.Layouts/globalStyle';
@import '../../3.Layouts/mixins';

.cards-container {
  height: auto;
  width: auto;
  border-radius: 1.5625rem;
  background: #f6f6f6;
  margin-top: 2.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-content: space-around;
  justify-content: center;
  align-items: stretch;
  justify-items: stretch;
  box-sizing: border-box;
  padding-right: 2.13rem;
  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 750px) {
    grid-template-columns: 1fr;
    justify-items: center;
    background: $whiteColor;
    display: list-item;
    padding-left: 0rem;
    padding-right: 0rem;
  }
}

.card-wrapper {
  width: 95%;
  height: 21.25rem;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(10, 10, 10, 0) 42%,
    rgba(4, 4, 4, 0.2) 99.99%,
    rgba(0, 0, 0, 0.5) 100%
  );
  background-color: $secondaireColor;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  cursor: pointer;
  border-radius: 0.625rem;
  margin: 1.5rem;
  text-decoration: none;
  @media screen and (max-width: 750px) {
    width: auto;
    min-width: 100%;
    position: relative;
    right: 1rem;
  }
}

.card-img {
  width: 90%;
  height: 12rem;
  border-radius: 0.625rem;
  margin: 1rem;
  object-fit: cover;
  position: relative;
  margin-bottom: revert;
  @media screen and (max-width: 750px) {
    width: auto;
    height: 13rem;
    right: auto;
  }
}

.card-text {
  color: #fff;
  font-family: Montserrat;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 142.6%;
  margin: 1rem;
  position: relative;
  top: 2rem;
}

.card-link {
  text-decoration: none;
}
