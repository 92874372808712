// Mixins
@import '../../3.Layouts/_mixins';

.contenaire_home {
  width: auto;
  margin-bottom: 2.36rem;
}

.contenaire_home {
  display: block;
  flex-direction: column;
  align-items: center;
  gap: 3.125rem;
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  @media screen and (max-width: 750px) {
    margin-left: 1.5rem;
    margin-right: 3.5rem;
  }
}

.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 13.9375rem;
  flex-shrink: 0;
  border-radius: 1.5625rem;
  background: #000;
  @include concept-title;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url('../../../Assets/images/home.png');
  background-size: cover;
  padding-left: 16px;

  @media screen and (max-width: 750px) {
    text-wrap: balance;
    font-size: 1.7rem;
    line-height: 100%;
    width: 100%;
    padding-left: 1.5rem;
    height: 6.93rem;
    padding-right: 1rem;
  }
}
