@import '../../3.Layouts/mixins';
@import '../../3.Layouts/globalStyle';

.footer_container{
    display: flex;
    width: auto;
    height: 13.0625rem;
    // padding: 4.125rem 29rem 1.8125rem 28.9375rem;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 1.78475rem;
    background: #000;
    color: $whiteColor;
    padding-top: 4.12rem;
    padding-bottom: 1.81rem;
    


.footer_logo{
    color: $whiteColor;
    width: 7.625rem;
    height: 2.46525rem;
    filter: brightness(3.5);
}

.footer_text{
    @include footer-style;
}
}